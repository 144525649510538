/* global $ */
import 'slick-carousel';
import BaseClass from '../base/baseClass';

class Slick extends BaseClass
{
    init() {
        console.log('Slick initialized');
        $('.slick-carousel').slick({
            autoplay: true,
        });
        $('.banner-slider').slick({
            autoplay: true,
            arrows: false,
            dots: true,
            fade: true,
            speed: 1500,
            autoplaySpeed: 6000,
            cssEase: 'linear'
        });
        $('.sponsor-listing').slick({
            autoplay: false,
            arrows: true,
            slidesToShow: 4,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            responsive: [{
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
            ]
        });
        $('.bedroom-listing').slick({
            autoplay: false,
            arrows: true,
            slidesToShow: 5,
            speed: 1000,
            loop: false,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
        });
        $('.bedroom-mobile-listing').slick({
            autoplay: false,
            arrows: true,
            speed: 1000,
            loop: false,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '80px',
            slidesToShow: 1,
        });
        $('.inner-testimonial-slider').slick({
            autoplay: true,
            arrows: true,
            slidesToShow: 1,
            speed: 1500,
            autoplaySpeed: 8000,
            slidesToScroll: 1,
            pauseOnHover:false,
        });
        $('.show-house-slider').slick({
            autoplay: true,
            arrows: true,
            slidesToShow: 1,
            speed: 1700,
            autoplaySpeed: 9000,
            slidesToScroll: 1,
            pauseOnHover:false,
        });
        $('.other-houses').slick({
            autoplay: true,
            arrows: true,
            slidesToShow: 1,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            pauseOnHover:false,
        });
        $('.other-plots').slick({
            autoplay: true,
            arrows: true,
            slidesToShow: 2,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            pauseOnHover:false,
            responsive: [{
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
            ]
        });
        $('.other-develop').slick({
            autoplay: true,
            arrows: true,
            slidesToShow: 2,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            pauseOnHover:false,
            responsive: [{
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
            ]
        });
        $('.full-page-gallery').slick({
            autoplay: true,
            arrows: true,
            slidesToShow: 1,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            pauseOnHover:false,
        });
        $('.plots-gallery').slick({
            autoplay: true,
            arrows: true,
            slidesToShow: 1,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            pauseOnHover:false,
        });

        $('.msg-slider').slick({
            slidesToScroll: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 4000,
            arrows: false,
            dots: false,
            vertical: true,
            verticalSwiping: true
        });

        var $st = $('.pagination');
        var $slickEl = $('.center');
        
        $slickEl.on('init reInit afterChange', function (slick, currentSlide) {
            var i = (currentSlide ? currentSlide : 0) + 1;
            $st.text(i + ' of ' + slick.slideCount);
        });
        
        $slickEl.slick({
            centerMode: true,
            centerPadding: '280px',
            slidesToShow: 1,
            focusOnSelect: true,
            speed: 1000,
            dots: false,
            infinite: true,
            prevArrow:'<button class="slick-prev"> < </button>',
            nextArrow:'<button class="slick-next"> > </button>',
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        arrows: true,
                        centerMode: true,
                        centerPadding: '220px',
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        arrows: true,
                        centerMode: true,
                        centerPadding: '100px',
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: true,
                        centerMode: true,
                        centerPadding: '80px',
                        slidesToShow: 1
                    }
                }
            ]
        });

        var homeGallerySlider = $('.home-gallery-slider');
        homeGallerySlider.on('init', function(event, slick) {
            var cur = $(slick.$slides[slick.currentSlide]);
            var next = cur.next();
            var prev = cur.prev();
            prev.addClass('slick-sprev');
            next.addClass('slick-snext');
            cur.removeClass('slick-snext').removeClass('slick-sprev');
            slick.$prev = prev;
            slick.$next = next;
        }).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            var cur = $(slick.$slides[nextSlide]);
            slick.$prev.removeClass('slick-sprev');
            slick.$next.removeClass('slick-snext');
            var next = cur.next();
            var prev = cur.prev();
            prev.prev();
            prev.next();
            prev.addClass('slick-sprev');
            next.addClass('slick-snext');
            slick.$prev = prev;
            slick.$next = next;
            cur.removeClass('slick-next').removeClass('slick-sprev');
        });

        homeGallerySlider.slick({
            speed: 1000,
            arrows: true,
            dots: false,
            focusOnSelect: true,
            infinite: true,
            centerMode: true,
            slidesPerRow: 1,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '0',
            swipe: true,
            rows: 0,
            customPaging: function() {
                return '';
            },
        });

        $('.instagramGallery').slick({
            autoplay: false,
            arrows: true,
            slidesToShow: 4,
            speed: 1000,
            autoplaySpeed: 4000,
            slidesToScroll: 1,
            responsive: [{
                breakpoint: 760,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '55px',
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '65px',
                }
            }
            ]
        });
    }
}

export default Slick;