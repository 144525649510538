/*global $*/
import BaseClass from '../base/baseClass';
import {Modal} from 'bootstrap';
class Teams extends BaseClass
{
    init() {
        console.log('Teams initialized');
        var _this = this;
        $('.openBtn').on('click',function(){
            
            var member_id = $(this).attr("data-id");
            console.log(member_id);
            _this.fetchMemberData(member_id, 'getMemberDetails');
        });
        $('.teamCat').on("change", function() {
            var categoryId = $('.teamCat').val();
            _this.fetchTeamBlock(categoryId, 'getCategoryTeam');
        });
    }
    fetchMemberData(member_id, url) {
        $('.modal-body').html('');
        $.ajax({
            type: 'get',
            url: '/' + url,
            dataType: 'html',
            data: { memberId: member_id },
            processData: true,
            success: function(result) {
                $('.modal-body').html(result);
            },
            error: function() {
                $('.modal-body').html('');
                var errorHtml = '';
                errorHtml += '<div class="col-12 col-md-12 itemFound">';
                errorHtml += '<span>Sorry! No relevant content found</span>';
                errorHtml += '</div>';
                $('.modal-body').html(errorHtml);
            }
        });
        var modal = new Modal(document.getElementById('myModal'));
        modal.show();
    }

    fetchTeamBlock(categoryId, url) {
        
        $.ajax({
            type: 'get',
            url: '/' + url,
            dataType: 'html',
            data: { categoryId: categoryId},
            processData: true,
            success: function(result) {
                $(".team-listing").html('');
                if ($.trim(result) == '')
                {
                    var resultHtml = '';
                    resultHtml += '<div class="col-12 col-md-12 itemFound">';
                    resultHtml += '<span>Sorry! No content</span>';
                    resultHtml += '</div>';
                    $(".team-listing").html(resultHtml);
                }
                else {
                    $(".team-listing").append(result);
                }
                new Teams();
            },
            error: function() {
                $(".team-listing").html('');
                var errorHtml = '';
                errorHtml += '<div class="col-12 col-md-12 itemFound">';
                errorHtml += '<span>Sorry! No relevant content found for this category</span>';
                errorHtml += '</div>';
                $(".team-listing").append(errorHtml);
            }
        });
    }
}

    

export default Teams;