/*global $*/
class Property
{
    constructor() {
        var webpath = window.location.pathname;
        this.scrollContainer = $('.properties-listing');
        this.isLoading = false;
        this.hasMore = true;
        this.propertyCount = 4;
        this.amount = 4;
        this.spinner = $('.spans');
        this.landingPage = ($("#getProperyAjax").length) ? true : false;
        this.houseId = $("#houseId").val();
        this.initInfiniteScroll(webpath);
        var _this = this;
        console.log('Property initialises');
        if( webpath == '/houses' || this.landingPage)
        {
            $('.eveCat').on("click", function() {
                $('.eveCat').removeClass('active');
                $(".allCat").removeClass("d-none");
                $(this).addClass('active');
                console.log('entered');
                var bedroom = $(this).data('id');
                $('#propertybedroom').val(bedroom);
                $('.properties-listing > .list-row').empty();
                $('.house-all-cattitle').addClass('d-none');
                _this.getFilterData(bedroom);
            });
        }
    }


    initInfiniteScroll(webpath)
    {
        console.log(webpath);
        $(window).scroll(() => {
            if( webpath == '/houses' || this.landingPage  )
            {
                let scroll = $(document).scrollTop() + $(window).height();
                let containerScroll = this.scrollContainer.offset().top + this.scrollContainer.outerHeight();
                if (scroll > containerScroll) {
                    this.loadMore();
                }
            }
        });
    }

    getFilterData(bedroom)
    {
        this.hasMore = true;
        this.spinner.show();
        $('.properties-listing > .list-row').empty();
        var propCat = $('#propertyCategory').val();
        $.ajax({
            url: '/getProperties',
            method: 'get',
            data: {
                houseId: this.houseId,
                propCat: propCat,
                bedroom: bedroom,
                offset: 0,
                limit: 3
            }
        }).done((data) => {
            let properties = $(data);
            $('.properties-listing > .list-row').html(properties);
            let count = $('.property-item').length;
            let total = $('.bedroom-category-heading').attr('data-category-houses');
            console.log(count + ' ' + total + 'ok');
            $('.properties-listing .property-item').fadeIn();
            this.propertyCount = count;
            if (count == total) {
                this.hasMore = false;
            }
        }).always(() => {
            this.isLoading = false;
            this.spinner.hide();
        })
    }

    loadMore() {
        
        console.log(this.hasMore);
        console.log(this.isLoading);

        if (this.isLoading || !this.hasMore) {
            return;
        }
       
        this.isLoading = true;
        this.spinner.show();
        var propCat = $('#propertyCategory').val();
        var bedroom = $('#propertybedroom').val();
        console.log(this.propertyCount);
        $.ajax({
            url: '/getProperties',
            method: 'get',
            data: {
                houseId: this.houseId,
                propCat: propCat,
                bedroom: bedroom,
                offset: this.propertyCount,
                limit: this.amount
            }
        }).done((data) => {
            let properties = $(data);
        
            $('.properties-listing > .list-row').append(properties);
            let count = $('.property-item').length;
            let total = $('.all-properties').attr('data-total');
            $('.properties-listing .property-item').fadeIn();
            this.propertyCount = count;
            if(bedroom > 0) {
                let total_category_houses = $('.bedroom-category-heading').attr('data-category-houses');
                if (count == total || count == total_category_houses) {
                    this.hasMore = false;
                }
            } else {
                if (count == total) {
                    this.hasMore = false;
                }
            } 
            
        }).always(() => {
            this.isLoading = false;
            this.spinner.hide();
        })
    }

 
}

export default Property;