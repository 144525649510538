/*global $*/
import BaseClass from '../base/baseClass';
import {Modal} from 'bootstrap';
class Features extends BaseClass
{
    init() {
        console.log('Feature initialized');
        var _this = this;
        $('.openFeature').on('click',function(){
            var feature_id = $(this).attr("data-id");
            _this.fetchFeatureData(feature_id, 'getFeature');
        });
    }
    fetchFeatureData(feature_id, url) {
        $('.modal-body').html('');
        $.ajax({
            type: 'get',
            url: '/' + url,
            dataType: 'html',
            data: { featureId: feature_id },
            processData: true,
            success: function(result) {
                $('.modal-body').html(result);
            },
            error: function() {
                $('.modal-body').html('');
                var errorHtml = '';
                errorHtml += '<div class="col-12 col-md-12 itemFound">';
                errorHtml += '<span>Sorry! No relevant content found</span>';
                errorHtml += '</div>';
                $('.modal-body').html(errorHtml);
            }
        });
        var modal = new Modal(document.getElementById('featureModal'));
        modal.show();
    }
}

    

export default Features;