/* global $ */
class Common
{
    constructor() {
        this.newsfilter();
        this.housefilter();
    }

    newsfilter(){
        $('.category-select').change(function(){
            var url =$(this).find(':selected').data('url');
            var value =$(this).find(':selected').val();
            var queryParams = new URLSearchParams(window.location.search);
            // Set new or modify existing parameter value. 
            queryParams.set("category", value);
            // Replace current querystring with the new one.
            history.replaceState(null, null, "?"+queryParams.toString());
            history.pushState(null, null, "?"+queryParams.toString());
            $.ajax({
                url:url,
                type:'GET', 
                success: function(data){
                    // $( ".news-items" ).load( "url .news-items" );
                    $('#news-items').html($(data).find('#news-items'));
                }
            });
        });
    }
    housefilter(){
        $('.badcat').click(function(){
            var url =$(this).data('url');
            var value =$(this).data('id');
            var queryParams = new URLSearchParams(window.location.search);
            // Set new or modify existing parameter value. 
            queryParams.set("category", value);
            // Replace current querystring with the new one.
            history.replaceState(null, null, "?"+queryParams.toString());
            history.pushState(null, null, "?"+queryParams.toString());
            $.ajax({
                url:url,
                type:'GET', 
                success: function(data){
                    // $( ".news-items" ).load( "url .news-items" );
                    $('#news-items').html($(data).find('#news-items'));
                }
            });
        });
    }
}

export default Common;